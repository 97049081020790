@import "../Palette.scss";

.search_filter{
	margin-bottom: 20px;
	position: relative;

	input{
		font-family: 'Playfair Display';
		padding: 6px 110px 6px 40px;
		border-width: 0 0 3px;
		font-weight: bold;
		font-size: 28px;
		outline: none;
		height: 50px;
		color: #000;
		width: 100%;

		@media screen and (max-width: 767px) {
			font-size: 22px;
		}

		&::placeholder {
		  color: #E5E5E5;
		  opacity: 1;
		}
		&:-ms-input-placeholder {
		  color: #E5E5E5;
		}
		&::-ms-input-placeholder {
		  color: #E5E5E5;
		}

		&:focus{
			border-color: #333;
		}

		@media screen and (max-width: 479px) {
			padding-right: 75px;
			font-size: 20px;
		}
	}

	.btn{
		right: 0;
		height: 35px;
		width: 100px;
		font-size: 16px;
		padding: 0 10px;
		font-weight: 500;
		border-radius: 25px;
		font-family: 'Avenir Next LT Pro';

		@media screen and (max-width: 479px) {
			font-size: 14px;
			height: 30px;
			width: 70px;
		}
	}

	.search_icon, .btn{
		position: absolute;
		z-index: 5;
		top: 5px;

		@media screen and (max-width: 479px) {
			top: 8px;
		}
	}

	.search_icon{
		max-width: 100%;
		left: 10px;
		top: 15px;
	}
}
