@import "../Palette.scss";

.post {
    &_lists {
        padding: 20px 0 20px;

        @media screen and (max-width: 575px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &_item {
        margin: 0;
        position: relative;
        overflow: hidden;
        &:not(:first-child) {
            margin-top: 10%;

            @media screen and (max-width: 479px) {
                margin-top: 16%;
            }
        }
    }

    &_title {
        margin-bottom: 25px;

        @media screen and (max-width: 767px) {
            margin-bottom: 15px;
        }

        h3 {
            font-family: "Avenir Next LT Pro";
            padding: 0 80px 0 30px;
            display: inline-block;
            margin-left: -15px;
            position: relative;
            font-weight: bold;
            font-size: 30px;

            @media screen and (max-width: 1080px) {
                font-size: 26px;
            }

            @media screen and (max-width: 767px) {
                font-size: 20px;
            }

            @media screen and (max-width: 767px) {
                padding: 0 50px 0 20px;
                font-size: 18px;
                margin-left: 0;
            }
        }

        span {
            background-color: $dark;
            display: inline-block;
            border-radius: 5px;
            padding: 5px 12px;
            margin: 0 8px;
            color: #fff;
        }

        img {
            position: absolute;
            margin: auto;
            bottom: 0;
            top: 0;

            &.wave_left {
                left: 0;
                max-width: 30px;

                @media screen and (max-width: 767px) {
                    max-width: 20px;
                }
            }

            &.wave_right {
                right: 0;
                max-width: 80px;

                @media screen and (max-width: 767px) {
                    max-width: 50px;
                }
            }
        }
    }

    &_content {
        margin-bottom: 10px;

        p {
            margin-bottom: 20px;

            @media screen and (max-width: 991px) {
                margin-bottom: 15px;
            }

            a:hover {
                color: $primary;
            }

            a {
                word-break: break-all;
            }
        }

        h4 {
            font-size: 28px;
            color: $primary;
            font-weight: bold;
            margin-bottom: 15px;
            font-family: "Avenir Next LT Pro";

            @media screen and (max-width: 991px) {
                font-size: 22px;
                margin-bottom: 10px;
            }
        }

        &_left {
            font-family: "Garamond";
            font-weight: normal;
            font-size: 24px;
            width: 35%;

            @media screen and (max-width: 991px) {
                width: 35%;
                font-size: 20px;
            }

            @media screen and (max-width: 479px) {
                width: 100%;
            }

            em {
                font-style: italic;
                font-size: 0.8em;
                margin-bottom: -15px;
                display: block;
            }
        }

        &_right {
            font-family: "Garamond";
            font-weight: bold;
            font-size: 18px;
            width: 55%;

            @media screen and (max-width: 991px) {
                font-size: 17px;
            }

            @media screen and (max-width: 479px) {
                width: 100%;
            }

            em {
                font-style: italic;
            }
        }

        @media screen and (max-width: 479px) {
            &.d-flex {
                display: block;
            }
        }
    }

    &_bottom {
        padding-top: 0px;
    }

    &_play {
        button {
            font-family: "Garamond";
            font-weight: normal;
            cursor: pointer;
            font-size: 22px;

            @media screen and (max-width: 991px) {
                font-size: 20px;
            }

            @media screen and (max-width: 575px) {
                font-size: 16px;
            }

            img {
                display: inline-block;
                vertical-align: -6px;
                margin-right: 3px;

                @media screen and (max-width: 991px) {
                    vertical-align: -8px;
                }
            }
        }
    }

    &_share {
        a {
            margin-left: 8px;

            img {
                max-width: 100%;
            }
        }
    }

    &_media {
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 25px;

        @media screen and (max-width: 991px) {
            margin-bottom: 20px;
        }

        @media screen and (max-width: 479px) {
            margin-bottom: 10px;
        }
    }
}

.watermark_title {
    font-family: "Playfair Display";
    transform: translateY(-25%);
    position: absolute;
    font-weight: bold;
    font-size: 18vw;
    left: -15vw;
    color: #f5f5f5;
    line-height: 1;
    z-index: -1;
    top: 25%;

    @media screen and (max-width: 1280px) {
        left: -12%;
    }
    @media screen and (max-width: 767px) {
        left: -6%;
    }

    @media screen and (max-width: 479px) {
        font-size: 55vw;
        top: 10%;
    }
}

.media_item {
    width: 33.33%;
    padding: 5px;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 479px) {
        width: 50%;
    }

    img {
        width: 100%;
        display: block;
    }

    .btn-play {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        margin: auto;
        position: absolute;
    }
}

.btn-play {
    display: inline-block;

    img {
        max-width: 100%;
    }
}
