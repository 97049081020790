@import "../Palette.scss";

#content_area {
    margin-bottom: 15px;
    position: relative;
    padding: 15px 0;

    @media screen and (max-width: 575px) {
        .container {
            width: 100%;
            padding: 0 5px;
        }
    }

    h1 {
        font-size: 20px;
        font-weight: bold;
        font-family: "Playfair Display";
    }

    p {
        margin: 10px 0;
    }

    form {
        display: flex;
        width: 100%;
        flex-direction: column;

        input,
        textarea {
            padding: 15px;
            margin: 5px 0;
            border: none;
            background-color: rgba(black, 0.03);

            &:focus {
                outline: none;
            }
        }
    }
}
