@import "../Palette.scss";

.header {
    padding: 15px 0 8px;
    width: 100%;

    .logo {
        color: $primary;
        font-size: 30px;
        font-weight: bold;
        font-family: "Playfair Display";

        @media screen and (max-width: 991px) {
            font-size: 24px;
        }

        @media screen and (max-width: 479px) {
            font-size: 20px;
        }
    }

    .header_links {
        a {
            color: $dark;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            font-family: "Avenir Next LT Pro";

            @media screen and (max-width: 991px) {
                font-size: 14px;
            }

            @media screen and (max-width: 479px) {
                font-size: 11.5px;
            }

            & + a {
                margin-left: 15px;

                @media screen and (max-width: 479px) {
                    margin-left: 3px;
                }
            }

            &:hover {
                color: $primary;
            }
        }
    }
}
