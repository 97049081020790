@import "../Palette.scss";

.poem_album_modal {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(black, 0.9);
    top: 0;
    right: 0;
    z-index: 100;

    &.active {
        display: block;
    }

    .modal_container {
        max-width: 1080px;
        margin: 0 auto;
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
        // padding: 80px 0;

        display: flex;
        flex-direction: column;
        color: white;

        .current_image {
            // height: 100%;
            max-height: 60vh;
            img {
                max-height: 60vh;
                max-width: 80vw;
            }
        }
        h2 {
            font-family: "Playfair Display";
            margin: 20px 20px 0 20px;
        }
        // background-color: white;

        .albumContent {
            text-align: center;
            background-color: white;
            padding: 20px;
            margin-top: 30px;
            color: black;
            display: flex;
            flex-direction: column;
            align-items: center;

            .thumbs {
                display: flex;
                .media_item {
                    max-width: 150px;
                }
            }

            .credit {
                text-align: center;
                margin-bottom: 10px;
            }
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;

            p {
                padding: 20px 30px;
            }
        }
    }
}
