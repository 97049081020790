@import "../Palette.scss";

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 10px;
    .prev {
    }
    .curr {
        h2 {
            margin: 0 30px;
            font-family: "Avenir Next LT Pro";
            padding: 8px;
        }
    }
    .next {
    }

    button {
        font-family: "Avenir Next LT Pro";
        background: none;
        border: 1px solid $primary;
        cursor: pointer;
        padding: 8px;

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: $primary;
            color: white;
        }
    }
}
