// Colors
$primary: #72a3af;
$secondary: #d2ffff;
$gray: #eeeeee;
$dark: #000;
$darker: #303232;

// Sizes
$contentWidth: 1150px;
$mobileWidth: 750px;

$primaryFont: "Geomanist";
$secondaryFont: "Lato";
