@import "../Palette.scss";

.banner {
  position: relative;

  .banner-image,
  img {
    width: 100%;
    display: block;
    max-width: 100%;
  }

  .banner_text {
    position: absolute;
    width: 80%;
    z-index: 2;
    bottom: 20%;

    @media screen and (max-width: 767px) {
      bottom: 10%;
      text-align: center;
      left: 10%;
    }

    h1,
    h2 {
      font-family: "Playfair Display";
      font-weight: 900;
      font-size: 58px;
      line-height: 1;
      max-width: 600px;

      @media screen and (max-width: 1230px) {
        font-size: 48px;
      }

      @media screen and (max-width: 767px) {
        font-size: 38px;
      }

      @media screen and (max-width: 575px) {
        font-size: 36px;
      }

      @media screen and (max-width: 479px) {
        font-size: 30px;
      }
    }

    h2 {
      font-size: 28px;

      @media screen and (max-width: 1230px) {
        font-size: 28px;
      }

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }

      @media screen and (max-width: 575px) {
        font-size: 16px;
      }

      @media screen and (max-width: 479px) {
        font-size: 14px;
      }
    }
  }
}
