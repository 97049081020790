@import "../Palette.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.post_navlist {
    background-color: $dark;
    position: relative;
    border-radius: 8px;
    display: block;
    margin: 0 10px 15px;
    padding: 20px 25px;

    @media screen and (max-width: 479px) {
        height: 48px;
    }

    @media screen and (max-width: 767px) {
        padding: 15px;
    }

    &:before,
    &:after {
        position: absolute;
        margin: auto;
        content: "";
        width: 0;
        right: 0;
        left: 0;
    }

    &:before {
        top: -1px;
        border-top: 6px #fff solid;
        border-left: 6px transparent solid;
        border-right: 6px transparent solid;
    }

    &:after {
        bottom: -1px;
        border-bottom: 6px #fff solid;
        border-left: 6px transparent solid;
        border-right: 6px transparent solid;
    }

    .btn-arrow {
        position: absolute;
        margin: auto;
        height: 23px;
        width: 23px;
        z-index: 5;
        bottom: 0;
        top: 0;

        img {
            max-width: 100%;
        }

        @media screen and (max-width: 479px) {
            width: 18px;
            height: 18px;
        }
    }

    .arrow-left {
        left: -10px;
    }

    .arrow-right {
        right: -10px;
    }

    .century {
        font-family: "Playfair Display";
        font-weight: 900;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        cursor: pointer;

        em {
            font-size: 0.6em;
        }
    }

    .year {
        padding: 0 5px;
        text-align: center;
        width: 100%;

        @media screen and (max-width: 767px) {
            padding: 0 2px;
        }

        .century {
            color: #fff;
            font-size: 42px;
            list-style: none;
            line-height: 42px;
            margin-top: -5px;
            margin-bottom: 5px;
            text-decoration: none;
            display: inline-block;
            vertical-align: middle;

            @media screen and (max-width: 991px) {
                font-size: 31px;
            }

            @media screen and (max-width: 767px) {
                font-size: 25px;
            }

            @media screen and (max-width: 479px) {
                font-size: 26px;
                margin-top: -22px;
            }

            opacity: 0.3;
            transform: scale(0.75) translateY(7px);
            transform-origin: center center;
        }

        &.active {
            .century {
                // transform: none;
                opacity: 1 !important;
            }

            @media screen and (max-width: 479px) {
                // max-width: 25px;
            }
        }

        &:hover {
            opacity: 1;
        }

        outline: none;
    }
}
