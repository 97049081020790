@import "./Palette.scss";

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 100.01%;
}

body,
textarea,
input {
    font-family: "Garamond";
    font-weight: normal;
    line-height: 1.3;
    font-size: 18px;
    color: $dark;
}

html,
body {
    // scroll-behavior: smooth;
}

.container {
    margin: auto;
    max-width: 1170px;
    padding: 0 15px;
    width: 85%;

    @media screen and (max-width: 767px) {
        width: 95%;
    }

    @media screen and (max-width: 479px) {
        width: 100%;
    }

    &-md {
        max-width: 1080px;
    }

    &-sm {
        max-width: 960px;
    }
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.btn {
    padding: 0 15px;
    cursor: pointer;

    &-reset {
        background: none;
        outline: none;
        border: none;
        padding: 0;
    }

    &-blue {
        color: #fff;
        background: $primary;
    }
}

a,
.btn,
.post_navlist li {
    transition: all 0.35s linear;
    -webkit-transition: all 0.35s linear;
    -moz-transition: all 0.35s linear;
    -ms-transition: all 0.35s linear;
    -o-transition: all 0.35s linear;
}

a {
    color: #000;
    text-decoration: none;
}

.d {
    &-flex {
        display: flex;
    }
}

.jc-between {
    justify-content: space-between;
}

.ai-end {
    align-items: flex-end;
}

.ai-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}
/* optional: show position indicator in red */

::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 50px;
}

::selection {
    background: $secondary;
    /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: $secondary;
    /* Gecko Browsers */
}
